import matter from 'gray-matter';

export async function getPost(slug: string) {
  const content = await import(`../posts/${slug}.md`);
  const data = matter(content.default);

  return {
    frontmatter: data.data,
    markdownBody: data.content,
    slug,
  };
}

export async function getPosts() {
  // const configData = await import('../siteconfig.json');

  const posts = (context => {
    const keys = context.keys();
    const values = keys.map(context);

    const data = keys.map((key, index) => {
      const slug = key.replace(/^.*[\\\\/]/, '').slice(0, -3);
      const value: any = values[index];
      const document = matter(value.default);
      return {
        frontmatter: document.data,
        markdownBody: document.content,
        slug,
      };
    });
    return data;
  })(require.context('../posts', true, /\.md$/));

  return posts;
}
